@-webkit-keyframes skeleton{
  0%{
    width:0%;
    left:0;
    right:auto;
    opacity:0.3; }
  20%{
    width:100%;
    left:0;
    right:auto;
    opacity:1; }
  28%{
    width:100%;
    left:auto;
    right:0; }
  51%{
    width:0%;
    left:auto;
    right:0; }
  58%{
    width:0%;
    left:auto;
    right:0; }
  82%{
    width:100%;
    left:auto;
    right:0; }
  83%{
    width:100%;
    left:0;
    right:auto; }
  96%{
    width:0%;
    left:0;
    right:auto; }
  100%{
    width:0%;
    left:0;
    right:auto;
    opacity:0.3; } }
@keyframes skeleton{
  0%{
    width:0%;
    left:0;
    right:auto;
    opacity:0.3; }
  20%{
    width:100%;
    left:0;
    right:auto;
    opacity:1; }
  28%{
    width:100%;
    left:auto;
    right:0; }
  51%{
    width:0%;
    left:auto;
    right:0; }
  58%{
    width:0%;
    left:auto;
    right:0; }
  82%{
    width:100%;
    left:auto;
    right:0; }
  83%{
    width:100%;
    left:0;
    right:auto; }
  96%{
    width:0%;
    left:0;
    right:auto; }
  100%{
    width:0%;
    left:0;
    right:auto;
    opacity:0.3; } }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--visually-hidden{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0, 0, 0, 0);
  border:0;
  visibility:visible;
  white-space:nowrap; }

.ccf .ccf--body{
  font-family:"Source Sans Pro", sans-serif;
  color:#555555;
  background-color:#0C1045;
  line-height:1; }

.ccf .mktoGen.mktoImg{
  display:block !important; }
.ccf body{
  min-height:100vh;
  font-family:"Source Sans Pro", sans-serif;
  color:#555555;
  background:#fff;
  line-height:1; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--type-legal{
  font-size:0.875rem;
  line-height:1.5;
  font-weight:400; }

.ccf .ccf--type-caps{
  text-transform:uppercase; }

.ccf strong,
.ccf .ccf--type-strong{
  font-weight:700; }

.ccf p,
.ccf .ccf--type-body1{
  margin:0 0 1rem;
  font-size:1rem;
  line-height:1.5;
  font-weight:400; }

.ccf em{
  font-style:italic; }

.ccf a{
  color:#085FA9; }

.ccf .ccf--type-body2{
  font-size:0.875rem;
  line-height:1.5;
  font-weight:400; }

.ccf .ccf--type-subtitle1{
  margin-top:0;
  font-size:2rem;
  line-height:1.5;
  font-weight:400; }

.ccf .ccf--type-subtitle2{
  margin-top:0;
  font-size:1.375rem;
  line-height:1.5;
  font-weight:400; }

.ccf .ccf--type-caption{
  font-size:0.75rem;
  line-height:1.5;
  font-weight:400; }

.ccf .ccf--type-button{
  margin:0;
  font-size:1rem; }

.ccf .ccf--type-button,.ccf .ccf--type-overline{
  line-height:1.1;
  font-weight:800;
  text-transform:uppercase; }

.ccf .ccf--type-overline{
  margin-bottom:0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:0.8125rem;
  letter-spacing:.1em; }

.ccf .ccf--type-overline + *{
  margin:0; }

.ccf h1,
.ccf .ccf--type-h1{
  margin-bottom:0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:2.25rem;
  line-height:1.1;
  letter-spacing:-1.5px;
  font-weight:600; }
  @media screen and (min-width: 992px){
    .ccf h1,
    .ccf .ccf--type-h1{
      font-size:4.375rem; } }

.ccf h2,
.ccf .ccf--type-h2{
  margin:1rem 0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1.875rem;
  line-height:1.1;
  font-weight:200; }
  @media screen and (min-width: 992px){
    .ccf h2,
    .ccf .ccf--type-h2{
      font-size:2rem; } }

.ccf h3,
.ccf .ccf--type-h3{
  margin:1rem 0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1.5rem;
  line-height:1.1;
  font-weight:400; }
  @media screen and (min-width: 992px){
    .ccf h3,
    .ccf .ccf--type-h3{
      font-size:2.375rem; } }

.ccf h4,
.ccf .ccf--type-h4{
  margin-bottom:0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1.5rem;
  line-height:1.1;
  letter-spacing:.25px;
  font-weight:500;
  text-transform:uppercase; }
  @media screen and (min-width: 992px){
    .ccf h4,
    .ccf .ccf--type-h4{
      font-size:2rem; } }

.ccf h5,
.ccf .ccf--type-h5{
  margin-bottom:0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1.375rem;
  line-height:1.5;
  letter-spacing:.15px;
  font-weight:500; }
  @media screen and (min-width: 768px){
    .ccf h5,
    .ccf .ccf--type-h5{
      font-size:1.375rem; } }

.ccf h6,
.ccf .ccf--type-h6{
  margin-bottom:0;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1rem;
  line-height:1.5;
  font-weight:500; }
  @media screen and (min-width: 768px){
    .ccf h6,
    .ccf .ccf--type-h6{
      font-size:1rem; } }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .site-header{
  display:block;
  padding:0 1.25rem 0.9375rem;
  text-align:center;
  background-color:white; }
  @media screen and (min-width: 768px){
    .ccf .site-header{
      display:flex;
      justify-content:space-between;
      padding:1.875rem; } }
  @media screen and (min-width: 992px){
    .ccf .site-header{
      padding:1.875rem 5rem; } }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .site-logo{
  display:block;
  padding:1.875rem 0;
  margin:0;
  max-width:340px; }
  @media screen and (min-width: 768px){
    .ccf .site-logo{
      margin:0;
      padding:0;
      width:30vw; } }
  .ccf .site-logo .site-logo__link{
    display:block; }

.ccf .site-logo__media{
  display:block;
  max-width:100%; }
  @media screen and (min-width: 768px){
    .ccf .site-logo__media{
      max-height:60px; } }

.ccf .site-logo__name{
  position:absolute;
  left:-999em; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .cta,
.ccf .cta:link,
.ccf .cta:visited{
  position:relative;
  display:block;
  padding:0.5rem 3.75rem 0.5rem 2.5rem;
  text-decoration:none;
  text-align:center;
  color:#085FA9;
  border-radius:6px;
  border-color:#DDDDDD;
  border-width:2px;
  border-style:solid;
  transition:all ease-in .125s;
  font-size:2.25rem; }
  .ccf .cta:after,
  .ccf .cta:link:after,
  .ccf .cta:visited:after{
    display:block;
    content:" ";
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    height:100%;
    width:2rem;
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/arrow-right.svg);
    background-repeat:no-repeat;
    background-position:center center;
    background-color:#F1F5F8;
    color:#085FA9;
    transition:all ease-in .125s; }
  .ccf .cta:hover, .ccf .cta:active, .ccf .cta:focus, .ccf .cta:link:hover, .ccf .cta:link:active, .ccf .cta:link:focus, .ccf .cta:visited:hover, .ccf .cta:visited:active, .ccf .cta:visited:focus{
    border-color:#0078BF;
    color:#0078BF;
    background:#F1F5F8; }
    .ccf .cta:hover:after, .ccf .cta:active:after, .ccf .cta:focus:after, .ccf .cta:link:hover:after, .ccf .cta:link:active:after, .ccf .cta:link:focus:after, .ccf .cta:visited:hover:after, .ccf .cta:visited:active:after, .ccf .cta:visited:focus:after{
      width:2.25rem;
      background-color:#0078BF;
      background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/arrow-right-white.svg); }
  .ccf .cta.cta--telephone:link, .ccf .cta.cta--telephone:visited, .ccf .cta:link.cta--telephone:link, .ccf .cta:link.cta--telephone:visited, .ccf .cta:visited.cta--telephone:link, .ccf .cta:visited.cta--telephone:visited{
    max-width:100%; }
  .ccf .cta.cta--primary:link, .ccf .cta.cta--primary:visited, .ccf .cta:link.cta--primary:link, .ccf .cta:link.cta--primary:visited, .ccf .cta:visited.cta--primary:link, .ccf .cta:visited.cta--primary:visited{
    font-weight:600;
    margin:0 auto;
    max-width:300px; }
    @media screen and (min-width: 1200px){
      .ccf .cta.cta--primary:link, .ccf .cta.cta--primary:visited, .ccf .cta:link.cta--primary:link, .ccf .cta:link.cta--primary:visited, .ccf .cta:visited.cta--primary:link, .ccf .cta:visited.cta--primary:visited{
        display:inline-block;
        width:100%;
        max-width:390px;
        font-size:4.375rem; } }
  @media screen and (min-width: 992px){
    .ccf .cta.cta--form-anchor,
    .ccf .cta:link.cta--form-anchor,
    .ccf .cta:visited.cta--form-anchor{
      display:none; } }

.ccf .content-aside--form .cta.cta--telephone.cta--primary:link, .ccf .content-aside--form .cta.cta--telephone.cta--primary:visited{
  width:auto;
  font-size:2.25rem; }
  @media screen and (min-width: 1200px){
    .ccf .content-aside--form .cta.cta--telephone.cta--primary:link, .ccf .content-aside--form .cta.cta--telephone.cta--primary:visited{
      font-size:2rem; } }

.ccf .soft-cta{
  color:#0078BF;
  font-weight:800;
  text-decoration:none; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .mktoForm{
  clear:both !important;
  margin:1rem 0 !important;
  width:auto !important;
  overflow:hidden !important; }

.ccf .mktoForm.form{
  margin:1rem auto !important;
  max-width:500px !important;
  border-bottom:1px solid #DDDDDD; }

.ccf .mktoForm .mktoError{
  top:4.75em !important;
  left:0 !important;
  right:auto !important;
  bottom:auto !important; }

.ccf .mktoForm .mktoFormCol{
  float:none !important; }

.ccf .mktoForm .mktoFormRow,
.ccf .mktoForm .mktoButtonRow{
  margin:0;
  background-color:transparent !important;
  text-align:left !important; }

.ccf .mktoForm .mktoButtonRow{
  padding:0 !important;
  width:100%;
  text-align:center !important; }

.ccf .mktoFormRow hr,.ccf .mktoOffset{
  display:none !important; }

.ccf .mktoLabel{
  float:left !important;
  margin-bottom:0 !important;
  width:auto !important;
  font-family:"Source Sans Pro", sans-serif;
  font-size:0.8125rem;
  line-height:1.1;
  font-weight:600 !important;
  text-transform:uppercase !important;
  letter-spacing:0.1em !important; }

.ccf .mktoAsterix{
  display:inline !important;
  color:red !important; }

.ccf .mktoField{
  padding:0.625rem !important;
  margin:0.5rem 0 !important;
  width:100% !important;
  border:1px solid #DDDDDD !important;
  border-radius:6px !important;
  box-sizing:border-box !important; }

.ccf .mktoField:focus{
  border:1px solid #085FA9;
  outline:none; }

.ccf .mktoFieldWrap .mktoLabel{
  width:auto !important; }

.ccf .mktoCheckboxList{
  clear:none !important;
  display:flex !important;
  flex-direction:row !important;
  width:100% !important;
  max-width:400px !important; }

.ccf .mktoCheckboxList label{
  margin:0 !important;
  font-family:"Source Sans Pro", sans-serif;
  font-size:1rem;
  line-height:1.1; }

.ccf .mktoCheckboxList .mktoField{
  width:auto !important;
  padding:0 !important;
  margin:0 0.5rem 0 0.25rem !important; }

.ccf .mktoDateButton{
  top:2em;
  right:1em; }

.ccf .mktoForm .mktoButtonWrap button.mktoButton{
  position:relative !important;
  display:block !important;
  padding:0.5rem 3.75rem 0.5rem 2.5rem !important;
  margin:1.25rem auto 0 !important;
  width:auto !important;
  font-weight:normal !important;
  font-family:"Source Sans Pro", sans-serif;
  font-size:2.25rem;
  line-height:1.1;
  color:white !important;
  background:#F18528 !important;
  border-color:#E9730E !important;
  border-radius:6px !important;
  border-width:2px !important;
  border-style:solid !important;
  transition:all ease-in .125s;
  text-shadow:none !important;
  text-align:center !important; }
  @media screen and (min-width: 1200px){
    .ccf .mktoForm .mktoButtonWrap button.mktoButton{
      font-size:2rem; } }
  .ccf .mktoForm .mktoButtonWrap button.mktoButton:after{
    display:block;
    content:" ";
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    height:100%;
    width:2rem;
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/arrow-right-white.svg);
    background-repeat:no-repeat;
    background-position:center center;
    background-color:#E9730E;
    border-radius:0 6px 6px 0;
    color:white;
    transition:all ease-in .125s; }
  .ccf .mktoForm .mktoButtonWrap button.mktoButton:hover, .ccf .mktoForm .mktoButtonWrap button.mktoButton:active, .ccf .mktoForm .mktoButtonWrap button.mktoButton:focus{
    background:#E9730E; }
    .ccf .mktoForm .mktoButtonWrap button.mktoButton:hover:after, .ccf .mktoForm .mktoButtonWrap button.mktoButton:active:after, .ccf .mktoForm .mktoButtonWrap button.mktoButton:focus:after{
      width:2.5rem;
      background-color:#ce670f; }

.ccf .site-main{
  overflow:hidden; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .picture{
  margin:0 -10vw;
  width:100vw; }

.ccf .picture__media img{
  width:200%; }
  @media screen and (min-width: 768px){
    .ccf .picture__media img{
      width:100%; } }

.ccf .left .picture__media img{
  -webkit-transform:translateX(0);
          transform:translateX(0); }
  @media screen and (min-width: 768px){
    .ccf .left .picture__media img{
      -webkit-transform:none;
              transform:none; } }

.ccf .center .picture__media img{
  -webkit-transform:translateX(-25%);
          transform:translateX(-25%); }
  @media screen and (min-width: 768px){
    .ccf .center .picture__media img{
      -webkit-transform:none;
              transform:none; } }

.ccf .right .picture__media img{
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%); }
  @media screen and (min-width: 768px){
    .ccf .right .picture__media img{
      -webkit-transform:none;
              transform:none; } }

.ccf .site-footer{
  padding:2.5rem 1.25rem; }
  @media screen and (min-width: 768px){
    .ccf .site-footer{
      display:flex;
      justify-content:start;
      padding:3.125rem 1.875rem 3.125rem; } }
  @media screen and (min-width: 992px){
    .ccf .site-footer{
      padding:2.5rem 5rem; } }

.ccf .site-footer__disclaimer{
  margin:0; }

.ccf .site-footer__navigation{
  line-height:1.5; }
  @media screen and (min-width: 768px){
    .ccf .site-footer__navigation{
      margin-left:3.125rem; } }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .content{
  padding:0;
  margin:0 auto;
  width:80vw; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

@media screen and (min-width: 1200px){
  .ccf .content-primary{
    padding:0;
    border-right:1px solid #DDDDDD; } }

.ccf .content-primary__lead-in{
  margin:0 0 1.875rem 0; }
  @media screen and (min-width: 1200px){
    .ccf .content-primary__lead-in{
      margin:0 3.75rem 1.875rem 0; } }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .highlights{
  position:relative;
  display:block;
  padding:0;
  margin:0 -10vw 1.875rem;
  width:100vw;
  background-color:#0078BF;
  color:white;
  overflow:hidden; }
  @media screen and (min-width: 992px){
    .ccf .highlights{
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      justify-content:center; }
      .ccf .highlights:after{
        content:" ";
        display:block;
        position:absolute;
        left:50%;
        top:10%;
        bottom:10%;
        border-left:2px solid #65BDF1;
        width:1px;
        height:80%;
        -webkit-transform:translateX(-50%);
                transform:translateX(-50%); } }
  @media screen and (min-width: 1200px){
    .ccf .highlights{
      width:49.5vw; } }

.ccf .highlights__content{
  position:relative;
  padding:0.9375rem 2.5rem;
  text-align:center;
  font-size:.5rem; }
  .ccf .highlights__content:first-child{
    padding-bottom:.5em;
    margin-bottom:1em; }
  .ccf .highlights__content:first-child:after{
    content:" ";
    display:block;
    position:absolute;
    bottom:0;
    left:10%;
    right:10%;
    border-bottom:2px solid #65BDF1; }
    @media screen and (min-width: 992px){
      .ccf .highlights__content:first-child:after{
        border-bottom:0; } }
  @media screen and (min-width: 992px){
    .ccf .highlights__content{
      flex:0 1 30%;
      padding:0.9375rem 1.25rem; } }
  @media screen and (min-width: 1200px){
    .ccf .highlights__content{
      padding:0.9375rem 0.9375rem; } }
  @media (min-width: bp--xl){
    .ccf .highlights__content{
      padding:0.9375rem 2.5rem; } }
  @media screen and (min-width: 992px){
    .ccf .highlights__content:first-child{
      margin-right:10%; } }
  @media screen and (min-width: 992px){
    .ccf .highlights__content:last-child{
      margin-left:10%; } }

.ccf .highlights__image{
  display:block;
  position:relative;
  z-index:1;
  margin:0 auto;
  max-width:134px;
  max-height:155px; }
  @media screen and (min-width: 992px){
    .ccf .highlights__image{
      position:absolute;
      left:50%;
      top:50%;
      -webkit-transform:translateX(-50%) translateY(-50%);
              transform:translateX(-50%) translateY(-50%);
      align-self:center;
      height:auto;
      margin:0; } }

.ccf .highlights__image img{
  display:block;
  max-width:100%; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .content-aside{
  text-align:center;
  background-color:white; }
  @media screen and (min-width: 992px){
    .ccf .content-aside{
      padding-left:1.875rem; } }
  .ccf .content-aside.content-aside--form{
    padding:1.875rem;
    border:2px solid #0078BF; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .specialty-list{
  margin:1.875rem;
  padding:0;
  list-style:none;
  text-align:left; }

.ccf .specialty-list__item{
  position:relative;
  margin:0 0 2rem;
  padding-left:3.875rem; }
  .ccf .specialty-list__item:before{
    content:" ";
    display:none;
    position:absolute;
    left:0;
    top:.25rem;
    width:2.5rem;
    height:2.5rem;
    background-position:center center;
    background-size:75%;
    background-repeat:no-repeat; }
  .ccf .specialty-list__item.specialty-list__item--phone:before{
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/icon-call.svg); }
  .ccf .specialty-list__item.specialty-list__item--representative:before{
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/icon-myconsult.svg); }
  .ccf .specialty-list__item.specialty-list__item--information:before{
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/icon-insurance.svg); }
  .ccf .specialty-list__item.specialty-list__item--consultation:before{
    background-image:url(https://pages.clevelandclinic.org/rs/434-PSA-612/images/icon-for-medical-professionals.svg); }

.ccf .specialty-list__icon img{
  display:block;
  position:absolute;
  left:0;
  top:.25rem;
  width:3rem;
  height:3rem;
  background-position:center center;
  background-size:75%;
  background-repeat:no-repeat; }

.ccf .specialty-list__title{
  margin:0;
  padding:0;
  font-size:2rem;
  line-height:1;
  font-weight:200; }

.ccf .specialty-list__description{
  font-size:1rem;
  line-height:1.5;
  font-weight:600; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .ccf--text-truncate--end,.ccf .ccf--text-truncate--front{
  width:100%;
  display:inline-block;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden; }

.ccf .ccf--text-truncate--front{
  direction:rtl; }

.ccf .columns.columns--two{
  display:block; }
  @media screen and (min-width: 1200px){
    .ccf .columns.columns--two{
      display:flex;
      margin:2.5rem 0 0; }
      .ccf .columns.columns--two section,
      .ccf .columns.columns--two aside{
        position:relative;
        flex:0 1 48vw;
        justify-content:space-between; } }
